import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import GalleryPageTemplate from '../components/GalleryPageTemplate';

const GalleryPage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.meta_title}</title>
        <meta name='description' content={post.frontmatter.meta_description} />
      </Helmet>
      <GalleryPageTemplate
        title={post.frontmatter.title}
        // meta_title={post.frontmatter.meta_title}
        // meta_description={post.frontmatter.meta_description}
        logo={post.frontmatter.logo}
        videos={post.frontmatter.videos}

      />
    </Layout>
  );
};
GalleryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};
export default GalleryPage;

export const galleryPageQuery = graphql`
  query GalleryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        logo
        meta_title
        meta_description
        videos
      }
    }
  }
`;
