import React from 'react';
import Gallery from '../Gallery';
import IframeResizer from 'iframe-resizer-react';
import { Grid } from '@material-ui/core';

const MaterijalPageTemplate = ({ title, logo, videos }) => (
  <div>
    <section className='hero is-primary is-bold is-medium'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-one-fifth is-1'>
              <img alt='' src={logo} />
            </div>
            <div className='column is-10'>
              <div className='section'>
                <h1 className='title'>{title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='hero'>
      <div className='hero-body'>
        <div className='container'>
          <Gallery />
        </div>
      </div>
    </section>
    <section className='hero'>
      <Grid container spacing={24} justify='center'>
        <ul className='taglist'>
          {videos && videos.length
            ? videos.map((video) => (
                <li key={video + `tag`}>
                  <IframeResizer
                    src={video}
                    style={{ width: '400px', height: '400px' }}
                  />
                </li>
              ))
            : null}
        </ul>
      </Grid>
    </section>
  </div>
);
export default MaterijalPageTemplate;
